<template>
  <base-layout>
    <h1 class="title">Users</h1>
    <div class="columns">
      <div class="column is-6-tablet is-5-desktop is-4-fullscreen">
        <div class="field has-addons">
          <p class="control is-expanded">
            <input
              type="text"
              class="input"
              v-model="searchFor"
              placeholder="Name, username, or account number starting with '#'"
            />
          </p>
          <p class="control">
            <button class="button is-link" @click="search">Search</button>
          </p>
        </div>
      </div>
    </div>
    <p class="content">
      Enter a full or partial name or username for a wildcard search. For an
      account number lookup, prepend the account number with a hash ('#').
    </p>

    <table v-if="users != null && users.length > 0" class="table">
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th>Full Name</th>
          <th>Account No</th>
          <th>Username</th>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <th>&nbsp;</th>
          <th>Full Name</th>
          <th>Account No</th>
          <th>Username</th>
        </tr>
      </tfoot>
      <tbody>
        <tr v-for="user in users" :key="user.demPortalUsername">
          <td>
            <font-awesome-icon
              icon="pencil-alt"
              @click="showPasswordModal(user.demPortalUsername)"
            />
          </td>
          <td>{{ user.name }}</td>
          <td>{{ user.dbtNo }}</td>
          <td>{{ user.demPortalUsername }}</td>
        </tr>
      </tbody>
    </table>
    <p class="content" v-else>
      <em>No users matching criteria</em>
    </p>
    <ValidationObserver
      slim
      v-slot="{ invalid }"
      tag="div"
      class="modal"
      :class="{ 'is-active': showPasswordModalFlag }"
    >
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Change Password</p>
          <button
            class="delete"
            aria-label="close"
            @click="hidePasswordModal"
          ></button>
        </header>
        <section class="modal-card-body">
          <p class="content">
            Changing password for user <strong>{{ selectedUser }}</strong>
          </p>
          <ValidationProvider
            rules="required|min:8|max:40|password:@confirm"
            tag="div"
            class="field"
            v-slot="{ errors }"
          >
            <div class="control">
              <input
                class="input"
                type="password"
                placeholder="New Password"
                v-model="newPassword"
                name="New Password"
              />
              <small class="has-text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="confirm"
            rules="required|min:8|max:40"
            tag="div"
            class="field"
            v-slot="{ errors }"
          >
            <div class="control">
              <input
                class="input"
                type="password"
                placeholder="Confirm New Password"
                v-model="confirmPassword"
                name="Confirm New Password"
              />
              <small class="has-text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
        </section>
        <footer class="modal-card-foot">
          <button
            class="button is-success"
            :disabled="invalid || $store.state.loading"
            @click="changePassword"
          >
            Save
          </button>

          <button class="button" @click="hidePasswordModal">Cancel</button>
        </footer>
      </div>
    </ValidationObserver>
  </base-layout>
</template>
<script>
import BaseLayout from "@/components/BaseLayout.vue";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "{_field_} is required"
});

extend("min", {
  validate(value, { length }) {
    return value.length >= length;
  },
  params: ["length"],
  message: "The {_field_} field must have at least {length} characters"
});

extend("max", {
  validate(value, { length }) {
    return value.length < length;
  },
  params: ["length"],
  message: "The {_field_} field must be less than {length} characters"
});
extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Password confirmation does not match"
});

export default {
  components: { BaseLayout, ValidationObserver, ValidationProvider },
  data() {
    return {
      users: null,
      searchFor: null,
      showPasswordModalFlag: false,
      selectedUser: null,
      newPassword: null,
      confirmPassword: null
    };
  },
  methods: {
    showPasswordModal(demPortalUsername) {
      this.selectedUser = demPortalUsername;
      this.newPassword = null;
      this.confirmPassword = null;
      this.showPasswordModalFlag = true;
    },
    hidePasswordModal() {
      this.selectedUser = null;
      this.newPassword = null;
      this.confirmPassword = null;
      this.showPasswordModalFlag = false;
    },
    async changePassword() {
      let pw = {
        user: this.selectedUser,
        newPassword: this.newPassword,
        confirmPassword: this.confirmPassword
      };
      await this.$store.dispatch("changePassword", pw);
      this.hidePasswordModal();
    },
    async search() {
      this.users = await this.$store.dispatch("fetchUsers", this.searchFor);
    }
  }
};
</script>
